@font-face {
  font-family: DSEG7ClassicMini-Bold;
  src: url("./fonts/DSEG7ClassicMini-Bold.ttf") format("opentype");
}

@font-face {
  font-family: Exo;
  src: url("./fonts/exo.otf") format("opentype");
}


.App {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bg-blur {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  opacity: 0.9;
  filter: blur(5px);
  z-index: -1;
}

.chicken-dance {
  width: 1514px;
  height: 835px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: url(./img/cursor/cursor_idle.png), auto;
  overflow: hidden;
  border: 1px solid white;
}

.chicken-dance:active {
  cursor: url(./img/cursor/cursor_click.png), auto;
}

.background {
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-position: bottom center;
  object-fit: cover;
  position: absolute;
  z-index: -1;
  pointer-events: none;
}

.frito {
  position: absolute;
  bottom: 125px;
  height: 110px;
  pointer-events: none;
  z-index: 1;
}


.ui-container {
  width: 220px;
  height: 111px;
  position: absolute;
  top: 30px;
  position: relative;
}

.ui-container.hide {
  animation: fadeOut 0.6s ease forwards;
}

.ui-img {
  width: 100%;
  position: absolute;
  z-index: -1;
  pointer-events: none;
}

.time {
  position: absolute;
  top: 11.5px;
  right: 27.5px;
  background: -webkit-linear-gradient(#FFFFFF, #FFAA00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: DSEG7ClassicMini-Bold;
  font-size: 32px;
  pointer-events: none;
}

.stage {
  font-family: Exo;
  font-size: 24px;
  color: white;
  position: absolute;
  top: 67px;
  right: 43px;
  pointer-events: none;
}

.arrow-container {
  position: absolute;
  top: 160px;
  display: flex;
  justify-content: center;
  pointer-events: none;
}

.arrow-inner {
  padding: 30px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 28px;
  background-image: url(./img/arrowcontainerbody.png);
  background-repeat: repeat;
  background-size: auto 100%;
}

.arrow-container.hide {
  /* display: none; */
  animation: fadeOut 0.6s ease forwards;
}


@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.arrow {
  width: 65px;
  border-radius: 100px;
  transition: opacity 0.8s ease;
  pointer-events: none;
}

.down {
  box-shadow: 0 0 10px 3px #FF1050;
}

.up {
  box-shadow: 0 0 10px 3px #FF8800;
}

.left {
  box-shadow: 0 0 10px 3px #4BBB00;
}

.right {
  box-shadow: 0 0 10px 3px #13AAEE;
}

.correct {
  animation: correct 0.2s linear forwards;
}

.wrong {
  animation: wrong 0.15s ease forwards;
}

@keyframes correct {
  0% {
    opacity: 1;
  }
  1% {
    opacity: 0.66;
    transform: scale(1.15);
    box-shadow: 0 0 20px 5px inherit;
  }
  24% {
    opacity: 0.66;
    transform: scale(1.15);
    box-shadow: 0 0 40px 10px inherit;
  }
  25% {
    opacity: 0.33;
    transform: scale(1.3);
    box-shadow: 0 0 40px 10px inherit;
  }
  49% {
    opacity: 0.33;
    transform: scale(1.3);
    box-shadow: 0 0 80px 20px inherit;
  }
  50% {
    opacity: 0.1;
    transform: scale(1.5);
    box-shadow: 0 0 80px 20px inherit;
  }
  74% {
    opacity: 0.1;
    transform: scale(1.5);
    box-shadow: 0 0 80px 20px inherit;
  }
  75% {
    opacity: 0;
    transform: scale(1.5);
    box-shadow: 0 0 80px 20px inherit;
  }
  100% {
    opacity: 0;
  }
}

@keyframes wrong {
  0% {
    opacity: 1;
    box-shadow: 0 0 20px 10px #ff0044;
  }
  100% {
    opacity: 0;
    box-shadow: 0 0 100px 20px #ff0044;
    transform: scale(1.4);
  }
}

.rooster {
  position: absolute;
  bottom: 125px;
  height: 90px;
  pointer-events: none;
  transition: opacity 0.8s ease;
}

.rooster.moveright {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.rooster.hidden {
  opacity: 0;
}

.start {
  position: absolute;
  top: 400px;
  /* height: 130px; */
  transform: translateY(-50%);
  object-position: center;
  pointer-events: none;
}

.start.hide {
  display: none;
}

.clear {
  position: absolute;
  /* height: 130px; */
  top: 400px;
  transform: translateY(-50%);
  object-fit: contain;
  object-position: center;
  pointer-events: none;
}

.clear.hide {
  display: none;
}

.fail {
  position: absolute;
  /* height: 130px; */
  top: 400px;
  transform: translateY(-50%);
  object-fit: contain;
  object-position: center;
  pointer-events: none;
}

.fail.hide {
  display: none;
}

.countdown {
  position: absolute;
  /* height: 130px; */
  top: 400px;
  transform: translateY(-50%);
  object-fit: contain;
  object-position: center;
  filter: saturate(0);
  pointer-events: none;
}

.countdown.hide {
  display: none;
}

.start-screen {
  position: absolute;
  display: flex;
  justify-content: center;
  top: 150px;
  padding-top: 15px;
  transition: all 0.5s ease;
  z-index: 2;
}

.start-screen.hide {
  opacity: 0;
}

.start-screen-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  width: 340px;
  margin-bottom: 45px;
  pointer-events: none;
}

.start-screen-bg {
  position: absolute;
  width: 1100px;
  top: 0;
  z-index: -1;
  pointer-events: none;
}

.maplelogo {
  width: 90px;
  margin-left: 12px;
  /* margin-bottom: 10px; */
  pointer-events: none;
}

.start-btn {
  width: 160px;
  height: 48px;
  background-image: url("./img/startbtn.png");
  background-repeat: no-repeat;
}

.start-btn:hover {
  background-image: url("./img/startbtn-hover.png");
}

.start-btn:active {
  background-image: url("./img/startbtn-pressed.png");
}

.result {
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 420px;
  top: 260px;
  transition: opacity 0.5s ease;
  z-index: 2;
}

.result.hide {
  opacity: 0;
}

.result-header {
  width: 100%;
  pointer-events: none;
}

.result-bg {
  margin-top: -5.5px;
  width: 100%;
  margin-bottom: 30px;
  pointer-events: none;
}

.result-time {
  font-size: 50px;
  font-family: Exo;
  color: #F8C71C;
  position: absolute;
  text-shadow:
   -1px -1px 0 white,  
    1px -1px 0 white,
    -1px 1px 0 white,
     1px 1px 0 white;
  top: 100.7px;
  left: 220px;
  font-size: 26px;
}

.result-time.stage {
  top: 136px;
}

.none {
  display: none;
}