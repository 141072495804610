* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  /* cursor: none; */
}

::-webkit-scrollbar {
  display: none;
}

html {
  overflow: hidden;
  height: 100%;
  /* cursor: none; */
}

body {
  width: 100vw;
  overflow-x: hidden;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* position: relative;
  display: flex;
  justify-content: center;
  align-items: center; */
  /* cursor: url(http://www.rw-designer.com/cursor-extern.php?id=35050), auto; */
}

#root {
  /* background-image: url(./img/chicken-dance-bg.png);
  background-size: cover;
  background-position: center; */
  /* z-index: -1; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
